<template>
  <div
    id="main-nav"
    class="relative z-[99]"
    :class="{
      'shadow-md': ctaOnly
    }"
  >
    <div
      class="bg-white relative w-full z-[999]"
      :class="{
        'shadow-header': hasShadow,
        'shadow-sm': !hasShadow
      }"
      @click="hideMenu"
    >
      <div
        ref="mainMenu"
        class="flex justify-between items-center max-w-8xxl mx-auto lg:pl-8 lg:pr-6 xl:px-14 flex-wrap lg:flex-nowrap h-[4rem] overflow-hidden"
        @click="hideMenu"
      >
        <div class="flex items-center h-[4rem] order-1">
          <nuxt-link to="/">
            <NuxtImg
              height="28"
              width="167"
              class="min-w-[9rem] max-w-[9rem] xs:max-w-unset xs:min-w-[10.4375rem] lg:w-auto py-3 pl-4"
              src="/images/cc-logo-new.svg"
              alt="Compare Club"
            />
          </nuxt-link>

          <template v-if="vertical && !excludeVertical">
            <!-- Horizontal Bar -->
            <div
              class="w-px mx-4 h-8 bg-dark hidden md:inline-block lg:hidden xl:inline-block"
              :class="{
                'hidden md:inline-block': ctaOnly || verticalData?.verticalTabletTitle === ''
              }"
            >
            </div>

            <a
              :href="verticalData?.productPage"
              class="font-bold lg:text-lg hidden md:inline-block lg:hidden xl:inline-block"
              :class="{
                'hidden md:inline-block': ctaOnly || verticalData?.verticalTabletTitle === ''
              }"
            >
              <span
                :class="{
                  'hidden lg:block': !ctaOnly && verticalData?.verticalTabletTitle !== ''
                }"
              >
                {{ verticalData?.title }}
              </span>

              <span
                :class="{
                  'lg:hidden': !ctaOnly,
                  'hidden': ctaOnly
                }"
              >
                {{ verticalData?.verticalTabletTitle }}
              </span>
            </a>
          </template>
        </div>

        <!-- NAV ITEMS -->
        <template v-if="!ctaOnly">
          <atoms-menu
            v-show="SHOW_MEGA_MENU"
            id="nav-menu"
            class="w-full bg-gray-5 lg:bg-white h-[3.875rem] lg:h-[4rem] lg:flex-1 order-3 lg:order-2 lg:justify-end border-t-gray-400 border-t lg:border-t-0 shadow-header-inset-light lg:shadow-none"
          >
            <atoms-menu-item
              v-for="(item, itemIndex) in menu"
              :key="itemIndex"
              :theme="devices.breakpoint('lg') ? item.theme : 'hover-with-border'"
              :is-dropdown="devices.breakpoint('lg') && item.type === 'dropdown'"
              :active="currMenu === item.slug"
              class="menu-container !text-dark grow lg:grow-0"
              inner-wrapper-class="justify-center p-0 lg:py-1 lg:px-2 xl:px-4 hover:lg:!border-b-transparent"
              padding="none"
              @click.stop="setCurrentMenu((item?.menu && item.slug) || null)"
            >
              {{ item.title }}
            </atoms-menu-item>
          </atoms-menu>

          <!-- OTHER MENU -->
          <atoms-menu class="order-2 lg:order-3 h-[4rem]">
            <!-- USER LOGIN -->
            <template v-if="!userLoggedIn && !hideSignupButtons">
              <atoms-menu-item
                padding="small"
                :to="!hasLoginPopup ? '/login/' : null"
                theme="global-navbar"
                class="menu-other min-w-12 xs:min-w-[3.25rem]"
                :class="{
                  'order-3 lg:order-none':rearrangeMenu
                }"
                inner-wrapper-class="!justify-center"
                @click="showClubPlusLogin"
              >
                <NuxtImg
                  src="/icons/user.svg"
                  alt="user"
                  class="ml-0"
                  width="24"
                  height="24"
                />

                <span class="hidden lg:block text-dark ml-2 mr-0">
                  Login
                </span>
              </atoms-menu-item>

              <atoms-menu-divider
                class="lg:hidden"
                :class="{
                  'order-4 lg:order-none':rearrangeMenu
                }"
              >
              </atoms-menu-divider>

              <atoms-menu-item
                padding="small"
                :to="!hasLoginPopup ? '/register/' : null"
                theme="global-navbar"
                class="menu-other hidden lg:inline-flex"
                @click="showClubPlusLogin"
              >
                <atoms-cta
                  theme="destructive"
                  size="micro"
                  class="!h-auto"
                >
                  Join the Club
                </atoms-cta>
              </atoms-menu-item>
            </template>

            <!-- PHONE MOBILE -->
            <template
              v-if="
                devices.breakpoint('md', 'max') &&
                  !ctaOnly &&
                  verticalData?.phoneNumber !== '' &&
                  hasCallCta &&
                  (
                    layout === 'default' ||
                    (layout === 'minimal' && (isQuoteResults || !userLoggedIn))
                  )
              "
            >
              <atoms-menu-item
                class="lg:hidden min-w-12 xs:min-w-[3.25rem]"
                padding="none"
                inner-wrapper-class="justify-center"
                :class="[
                  {
                    'min-w-[3.25rem]': !userLoggedIn && layout === 'minimal',
                    'order-1 lg:order-none':rearrangeMenu
                  },
                ]"
                :to="`tel:${verticalData?.phoneNumber}`"
                :has-background-when-active="false"
              >
                <NuxtImg
                  src="/icons/phone-primary.svg"
                  class="w-6 h-6 mx-auto"
                  alt="phone"
                  width="24"
                  height="24"
                />
              </atoms-menu-item>

              <atoms-menu-divider
                v-if="
                  layout === 'default' ||
                    (layout === 'minimal' && isQuoteResults && userLoggedIn)
                "
                class="lg:hidden"
                :class="{
                  'order-2 lg:order-none':rearrangeMenu
                }"
              >
              </atoms-menu-divider>
            </template>

            <!-- NOTIFICATION -->
            <template
              v-if="!ctaOnly && (layout === 'default' || hideSignupButtons) && userLoggedIn && enableContentCardNotifications">
              <MoleculesNavNotification/>

              <AtomsMenuDivider class="lg:hidden"/>
            </template>

            <!-- USER LOADER -->
            <atoms-menu-item
              v-if="hideSignupButtons && !userLoggedIn"
              class="hidden lg:flex items-center min-w-32 h-full px-2 xs:px-4 animate-pulse"
            >
              <svg
                width="32"
                height="32"
                class="w-6 h-6 text-gray-500"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path
                  d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z"/>
              </svg>

              <div class="h-4 bg-gray-400 ml-2 min-w-12"></div>

              <span class="ml-2">
                <IconsChevron
                  class="ml-2 w-4 h-4 relative top-[0.0625rem]"
                  type="thin"
                  direction="bottom"
                />
              </span>
            </atoms-menu-item>

            <!-- NAV PROFILE -->
            <atoms-menu-item
              v-if="
                userLoggedIn &&
                  ((layout === 'default' ||(layout === 'minimal' && forceShowNavProfile)) || (layout === 'minimal' && devices.breakpoint('lg')))

              "
              padding="none"
              theme="global-navbar"
              class="menu-other min-w-12 xs:min-w-[3.25rem] mr-2 md:mr-0"
              inner-wrapper-class="!justify-center"
              :has-background-when-active="false"
            >
              <MoleculesNavProfile
                class="w-full flex items-center justify-center min-w-12"
                :icon-only="devices.breakpoint('md', 'max')"
                :has-background-when-active="true"
              />
            </atoms-menu-item>

            <!-- SPEAK TO US -->
            <template v-if="verticalData?.phoneNumber !== '' && hasCallCta">
              <AtomsMenuDivider class="pr-2 lg:pr-4 hidden lg:inline-flex"/>
              <atoms-menu-item
                :to="`tel:${verticalData?.phoneNumber}`"
                :has-background-when-active="false"
                class="menu-other relative min-w-[8.25rem] hidden lg:inline-flex"
                padding="none"
                @mouseover="showSpeakToUsTooltip = true"
                @mouseleave="showSpeakToUsTooltip = false"
              >
                <div class="block text-right">
                  <div class="flex items-center justify-end mt-3">
                    <NuxtImg
                      src="/icons/phone-primary.svg"
                      class="mr-1"
                      alt="phone"
                      width="14"
                      height="14"
                    />

                    <atoms-badge
                      size="sm"
                      class="text-xs px-2"
                    >
                      Speak to us
                    </atoms-badge>
                  </div>

                  <div class="flex items-center justify-end mb-2">
                    <span class="text-dark text-base">
                      {{ verticalData?.phoneNumberDisplay || verticalData?.phoneNumber }}
                    </span>
                  </div>
                </div>

                <atoms-tooltip
                  v-model="showSpeakToUsTooltip"
                  type="call-hours"
                  tip-x="right"
                  tip-y="top"
                  :has-icon="false"
                  class="absolute right-6 top-14 w-[12.25rem] z-[51] text-left"
                  @mouseover="showSpeakToUsTooltip = true"
                  @mouseleave.stop
                >
                  <NuxtImg
                    src="icons/clock.svg"
                    class="mt-[0.125rem] mr-[0.313rem] inline-block align-top"
                    alt="clock"
                    :width="12.94"
                  />

                  <p class="inline-block text-left">
                    <span class="flex items-center justify-start">
                      {{ verticalData?.schedule }}
                    </span>

                    <span class="flex items-center justify-start">
                      {{ verticalData?.schedule_weekend }}
                    </span>
                  </p>
                </atoms-tooltip>
              </atoms-menu-item>
            </template>

            <!-- HAMBURGER -->
            <template v-if="!ctaOnly && layout === 'default'">
              <atoms-menu-divider
                v-if="userLoggedIn"
                class="lg:hidden"
                :class="{
                  'order-4 lg:order-none':rearrangeMenu
                }"
              >
              </atoms-menu-divider>

              <li
                class="min-w-[2.5rem] lg:hidden"
                :class="{
                  'order-5 lg:order-none':rearrangeMenu
                }"
              >
                <button
                  class="inline-flex items-center text-sm h-full w-full"
                  type="button"
                  @click.stop="toggleMegaMenu"
                >
                  <NuxtImg
                    v-show="!showMegaMenuFlag"
                    src="/icons/burger-dark.svg"
                    alt="burger"
                    height="24"
                    class="mx-auto w-6 h-6 md:w-auto"
                    width="24"
                  />

                  <NuxtImg
                    v-show="showMegaMenuFlag"
                    src="/icons/close-dark.svg"
                    alt="close"
                    height="24"
                    class="mx-auto w-6 md:w-auto"
                    width="24"
                  />
                </button>
              </li>
            </template>
          </atoms-menu>
        </template>

        <!-- FOR CONTENTFUL ARTICLELIGHTWITHSIDEBAR -->
        <div
          v-else
          class="py-3 order-2 pr-4"
        >
          <atoms-cta
            :to="verticalData?.formPage"
            :size="devices.breakpoint('md') ? 'medium' : 'xsmall'"
            theme="primaryWhite"
            class="font-bold px-4"
          >
            {{ ctaText }}
          </atoms-cta>
        </div>
      </div>

      <OrganismsNavMegaMenu
        :menu="menu"
        :show-curr-menu="showMegaMenuFlag"
        :curr-menu="currMenu"
        :has-call-cta="hasCallCta"
        :user-logged-in="userLoggedIn"
        :vertical-data="verticalData"
        @redirect="hideMenu"
      />
    </div>

    <div
      v-if="showMegaMenuFlag"
      ref="backdrop"
      class="bg-black fixed w-full h-full top-0 left-0 z-[60] opacity-50 block"
      @click="hideMenu"
    >
    </div>

    <MoleculesRateRiseNudgeBar
      :show="showRateRiseNudgeBar"
      :config="dualRateConfig"
    />

    <OrganismsMemberhubModalAuthentication
      v-if="hasLoginPopup && !userLoggedIn"
    />
  </div>
</template>

<script setup>
import { storeToRefs } from 'pinia'

import { NAV_MENU_OBJ } from '~/constants/navbar.constants'

const { devices } = deviceChecker()
const {
  $tracking,
  $bus
} = useNuxtApp()

const { fetchMenuArticles } = useNavigationStore()
const userStore = useUserStore()
const layoutStore = useLayoutsStore()
const {
  vertical,
  navigation
} = storeToRefs(layoutStore)

const rateRiseNudgebar = useRateRiseNudgebarStore()
const {
  dualRateConfig,
  showRateRiseNudgeBar
} = storeToRefs(rateRiseNudgebar)

const { enableContentCardNotifications } = useRuntimeConfig().public

defineOptions({
  name: 'OrganismsNav'
})

const route = useRoute()
const props = defineProps({
  layout: {
    type: String,
    default: 'default',
    validator: value => ['default', 'minimal'].includes(value)
  },

  ctaOnly: {
    type: Boolean,
    default: false
  },

  ctaText: {
    type: String,
    default: 'GET FREE QUOTES'
  },

  hasShadow: {
    type: Boolean,
    default: false
  },

  hasCallCta: {
    type: Boolean,
    default: true
  },

  hideSignupButtons: {
    type: Boolean,
    default: false
  },

  hasLoginPopup: {
    type: Boolean,
    default: false
  }
})

const $route = useRoute()

/**
 * Fetch the article list for feature menu items
 */
if (import.meta.server) {
  try {
    await fetchMenuArticles({ verticals: VERTICAL_LIST })
  } catch (error) {
    throw new Error('Navigation:fetchData:Fail:' + String(error))
  }
}

const mainMenu = ref(null)
const currMenu = ref('')
const showSpeakToUsTooltip = ref(false)
const showMegaMenuFlag = ref(false)

const isClient = computed(() => import.meta.client)

const SHOW_MEGA_MENU = computed(() => {
  if (!isClient.value) {
    return true
  }

  if (!devices.breakpoint('md', 'max')) {
    return true
  }

  return showMegaMenuFlag.value
})

const userLoggedIn = computed(() => userStore.isUserLoggedIn)
const rearrangeMenu = computed(() => (props.layout === 'default' && !userLoggedIn.value))

const menu = computed(() => {
  if (props.layout === 'minimal') {
    return {}
  }

  return NAV_MENU_OBJ
})

const forceShowNavProfile = computed(() => {
  const _path = $route.path

  return (
    _path.includes('dashboard') ||
      _path.includes('quotes-results')
  )
})

const isQuoteResults = computed(() => $route.path.includes('quotes-results'))

const excludeVertical = computed(() => {
  return route.path.includes('/partners/add')
      || route.path.includes('/partners/signup')
})

const verticalData = computed(() => {
  const vert = vertical.value || 'Default'
  const data = VERTICALS?.[vert]

  const isIncomeProtection = route.path.includes('income-protection')
  const isOVHC = route.path.includes('visitors-visa-ovhc')

  if (isIncomeProtection) {
    data.schedule = 'Mon-Thu 9:30am-6pm AEST'
    data.schedule_weekend = 'Fri 9am-5pm AEST'
  }

  if (isOVHC) {
    data.phoneNumber = '1300 084 008'
    data.phoneNumberDisplay = '1300 084 008'
  }

  return data
})

onMounted(async () => {
  await nextTick()

  if (mainMenu.value) {
    mainMenu.value.style.height = 'auto'
    mainMenu.value.style.overflow = 'unset'
  }
})

watch(showMegaMenuFlag, value => {
  navigation.showMegaNav = value
})

/**
 * Force close menu when breakpoint changes
 * and if showMegaMenuFlag is true
 */
watch(devices.currBreakpoint, () => {
  if (showMegaMenuFlag.value) {
    showMegaMenuFlag.value = false
    currMenu.value = ''
  }
})

function setCurrentMenu (val) {
  if (!val) {
    return
  }

  if (currMenu.value === val && showMegaMenuFlag.value) {
    currMenu.value = ''
    showMegaMenuFlag.value = false
    return
  }

  currMenu.value = val
  showMegaMenuFlag.value = true
}

function hideMenu ($event) {
  const type = $event.type

  if (
    (['mouseleave', 'mouseover'].includes(type) && devices.breakpoint('lg')) ||
      type === 'click'
  ) {
    showMegaMenuFlag.value = false
    currMenu.value = ''
  }
}

function toggleMegaMenu () {
  showMegaMenuFlag.value = !showMegaMenuFlag.value

  currMenu.value = 'insurance'
}

function showClubPlusLogin () {
  if (props.hasLoginPopup) {
    $tracking.customEvent('Club+ Modal', {})
    $bus.emit('mh-auth', { source_page_url: $route.fullPath, query: $route.query })
  }
}

const { $pwa } = useNuxtApp()

const isPWAApp = computed(() => {
  return $pwa?.isPWAInstalled
})
</script>
